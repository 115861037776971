import React, { useState, useEffect } from "react";
import { SiSuzuki } from "react-icons/si";

const BrandList = ({ selectedCategories }) => {
  const [selectedTab, setSelectedTab] = useState(selectedCategories || 1);
  const [brands, setBrands] = useState([]);
  const [isViewAll, setIsViewAll] = useState(false);

  const tabs = [
    { id: 1, category: "Car" },
    { id: 2, category: "Bike" },
    { id: 3, category: "Tracktor" },
    { id: 4, category: "Commercial" },
  ];

  const getVisibleCards = () => {
    if (window.innerWidth >= 1024) return 7; // Large screens
    if (window.innerWidth >= 768) return 5; // Medium screens
    return 4; // Small screens
  };

  const fetchBrandList = async (categoryId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getbrand`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ categID: categoryId }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch brand list");
      }

      const data = await response.json();
      setBrands(data.result || []);
    } catch (err) {
      console.error(err);
      setBrands([]);
    }
  };

  useEffect(() => {
    if (selectedCategories) {
      setSelectedTab(selectedCategories);
    }
  }, [selectedCategories]);

  useEffect(() => {
    fetchBrandList(selectedTab);
  }, [selectedTab]);

  return (
    <>
      <div className="bg-white shadow-md rounded-t-md mt-3">
        <div>
          <h4 className="text-sm md:text-xl font-bold pt-3 pb-3 pl-3">
            Search By Brand
          </h4>
          <div className="flex gap-8">
            {tabs.map((tab) => (
              <div key={tab.id} className="relative cursor-pointer">
                <h4
                  onClick={() => {
                    setSelectedTab(tab.id);
                    setIsViewAll(false); // Reset view to default when tab changes
                  }}
                  className={`pb-3 pl-5 ${selectedTab === tab.id
                    ? "text-orange-500"
                    : "text-gray-700"
                    }`}
                >
                  {tab.category}
                </h4>
                {selectedTab === tab.id && (
                  <div className="absolute bottom-0 left-0 w-full h-1 bg-orange-500"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-b-md mt-0.5">
        <div
          className={`flex ${isViewAll ? "flex-wrap justify-start gap-4" : "overflow-x-hidden space-x-4"
            } p-4`}
        >
          {Array.isArray(brands) &&
            (isViewAll
              ? brands
              : brands.slice(0, getVisibleCards())
            ).map((item) => (
              <div
                key={item.intId}
                className="bg-white w-14 h-12 md:w-16 lg:w-28 lg:h-20 border border-gray-300 rounded-xl flex flex-col items-center justify-center text-center mb-4 flex-shrink-0"
              >
                <i className="text-red-700 text-2xl">
                  <SiSuzuki className="text-xl lg:text-3xl" />
                </i>
                <h4 className="text-xs text-blue-700 font-bold">
                  {item.VCHBRAND}
                </h4>
              </div>
            ))}
        </div>
        <div className="flex justify-end px-4 pb-2">
          <button
            className="text-sm font-bold text-orange-500"
            onClick={() => setIsViewAll((prev) => !prev)}
          >
            {isViewAll ? "View Less" : "View All"}
          </button>
        </div>
      </div>
    </>
  );
};
export default BrandList;

import React, { useState, useRef, useEffect } from "react";

const ReviewCard = () => {
  const scrollRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [testimonials, setTestimonials] = useState([]);

  const fetchReviews = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getReviews`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // Add payload if necessary
      });

      if (!response.ok) throw new Error("Failed to fetch reviews");
      const data = await response.json();
      setTestimonials(data.result || []); // Adjust based on API structure
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    const slider = scrollRef.current;
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const slider = scrollRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;

    slider.style.scrollBehavior = "smooth";
    slider.scrollLeft = scrollLeft - walk;
  };

  const resetScrollBehavior = () => {
    if (scrollRef.current) {
      scrollRef.current.style.scrollBehavior = "smooth";
    }
  };

  return (
    <div className="max-w-screen-xl bg-white shadow-md rounded-md mx-auto px-2 py-3 mt-3">
      <h2 className="text-sm md:text-xl font-bold mb-3">Testimonial</h2>

      <div
        ref={scrollRef}
        className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide cursor-grab active:cursor-grabbing"
        onMouseDown={handleMouseDown}
        onMouseLeave={(e) => {
          handleMouseLeave(e);
          resetScrollBehavior();
        }}
        onMouseUp={(e) => {
          handleMouseUp(e);
          resetScrollBehavior();
        }}
        onMouseMove={handleMouseMove}
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          userSelect: "none",
          WebkitUserSelect: "none",
          scrollBehavior: "smooth",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        }}
      >
        <div className="flex gap-2 md:gap-4 pb-4 mx-10">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="flex-none w-[45%] md:w-[23%] snap-start transform transition-transform duration-300 ease-out"
            >
              <div
                className="h-full flex flex-col items-center border rounded-lg shadow-md p-2 md:p-6 bg-white text-center transition-all duration-300 ease-in-out hover:shadow-lg"
                style={{
                  transform: isMouseDown ? "scale(0.98)" : "scale(1)",
                  transition: "transform 0.3s ease-out",
                }}
              >
                {/* <img
                  src={testimonial.img}
                  alt={testimonial.user_name}
                  className="w-12 h-12 md:w-16 md:h-16 rounded-full mb-2 object-cover select-none transition-transform duration-300 ease-in-out hover:scale-105"
                  draggable="false"
                /> */}
                <h3 className="text-sm md:text-base font-semibold mb-1">
                  {testimonial.user_name}
                </h3>
                <p className="text-gray-600 text-xs mb-2 line-clamp-3">
                  {testimonial.comment}
                </p>
                <div className="text-yellow-400 text-sm mt-auto">
                  {"★".repeat(testimonial.rating)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
import React from "react";

// Dummy Data for Featured Boxes
const featuredItems = [
    { id: 1, color: "bg-red-200" },
    { id: 2, color: "bg-green-100" },
    { id: 3, color: "bg-blue-100" },
    { id: 4, color: "bg-green-200" },
    { id: 5, color: "bg-purple-200" },
    { id: 6, color: "bg-red-200" },
];

const Featured = () => {
    return (
        <div className="max-w-screen-xl bg-white shadow-md rounded-md mx-auto px-4 py-3 mt-3">
            <h2 className="text-sm md:text-xl font-bold mb-3">Featured In</h2>
            {/* Featured Boxes */}
            <div className="flex justify-between items-center overflow-x-auto gap-4">
                {featuredItems.map((item) => (
                    <div
                        key={item.id}
                        className={`w-32 h-20 rounded-lg ${item.color} shadow-md`}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default Featured;

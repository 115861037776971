import React, { useState } from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./Components/Header/header";
import Home from "./Components/Home/home";
import Footer from "./Components/Footers/footer";
import DetailsPage from "./Components/DetailsPage/detailesPage";
import LoginForm from "./Components/profile/login";
import RegisterForm from "./Components/profile/register";
import OtpCode from "./Components/profile/otp";
import { use } from "react";
import RTOchallan from "./Components/OurServices/RTOchallan";
import RTOdetails from "./Components/OurServices/RTO-details";
import PersonalLoan from "./Components/OurServices/personalLoan";
import ScrollToTop from "./scroll";
import Insurance from "./Components/OurServices/insurance";

const App = () => {
  const [logData, setLogData] = useState('');
  const [proId, setProID] = useState('');

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop here */}
      <Header />
      <Routes>
        <Route exact path="/" element={<Home setProID={setProID} />} />
        <Route exact path="/details" element={<DetailsPage proId={proId} />} />
        <Route exact path="/login" element={<LoginForm setLogData={setLogData} />} />
        <Route exact path="/register" element={<RegisterForm />} />
        <Route exact path="/otp" element={<OtpCode logData={logData} />} />
        <Route exact path="/RTO-challan" element={<RTOchallan />} />
        <Route exact path="/RTO-details" element={<RTOdetails />} />
        <Route exact path="/personal-loan" element={<PersonalLoan />} />
        <Route exact path="/insurance" element={<Insurance />} />
      </Routes>
      <Footer />
    </Router>
  )
};
export default App;
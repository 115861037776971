import React, { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { PiUserCircleLight } from "react-icons/pi";
import { MdLogin, MdPersonAdd } from "react-icons/md"; // Login and Register icons
import { BiLogOut } from "react-icons/bi";
import { RiUser3Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const DropDownUser = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleMouseEnter = () => setIsDropdownOpen(true);
    const handleMouseLeave = () => setIsDropdownOpen(false);

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const isLogged = localStorage.getItem('isLogged');

    const handleLogout = () => {
        localStorage.removeItem("isLogged");
        window.location.reload(); // Reload or redirect to login after logout
    };

    return (
        <div
            className="relative" // To position dropdown relative to this container
            onMouseLeave={handleMouseLeave}
        >
            {/* For larger screens */}
            <div className="hidden md:block">
                <button
                    className="flex gap-2 text-gray-600 hover:text-gray-800"
                    onMouseEnter={handleMouseEnter}
                    onClick={() => setIsDropdownOpen(false)}
                >
                    <FaRegUser size={20} />
                    {isLogged ? "Welcome" : "Login / Register"}
                </button>
            </div>

            {/* For mobile screens */}
            <div className="block md:hidden" onClick={toggleDropdown}>
                <button className="flex gap-2 text-gray-600 hover:text-gray-800">
                    <PiUserCircleLight size={40} />
                </button>
            </div>

            {/* Dropdown Content */}
            {isDropdownOpen && (
                <div className="absolute top-6 md:top-4 -left-24 md:left-0 w-40 bg-white border border-gray-200 shadow-md rounded-md z-50 mt-2">
                    <ul className="text-gray-700">
                        {isLogged ? (
                            <>
                                <li>
                                    <Link
                                        to="/profile"
                                        className="flex items-center px-4 py-2 hover:bg-gray-100 hover:text-orange-500"
                                    >
                                        <RiUser3Line className="mr-2 text-lg" />
                                        <span>Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <button
                                        className="flex items-center px-4 py-2 w-full text-left hover:bg-gray-100 hover:text-orange-500"
                                        onClick={handleLogout}
                                    >
                                        <BiLogOut className="mr-2 text-lg" />
                                        <span>Logout</span>
                                    </button>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link
                                        to="/login"
                                        className="flex items-center px-4 py-2 hover:bg-gray-100 hover:text-orange-500"
                                    >
                                        <MdLogin className="mr-2 text-lg" />
                                        <span>Login</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/register"
                                        className="flex items-center px-4 py-2 hover:bg-gray-100 hover:text-orange-500"
                                    >
                                        <MdPersonAdd className="mr-2 text-lg" />
                                        <span>Register</span>
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DropDownUser;

import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const LoginForm = ({ setLogData }) => {
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior

        setLoading(true); // Set loading state to true
        setError(""); // Reset error message

        const requestBody = {
            mobile: mobile,
            password: password,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api_login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error("Failed to log in");
            }

            const data = await response.json();
            console.log(data); // Handle the response data as needed

            if (data.message === "OTP sent to your mibile number") {
                navigate('/otp');
                setLogData({
                    mobile: mobile, 
                    password: password
                });
            }

            // You can redirect or show a success message here
        } catch (error) {
            setError(error.message); // Display error message if request fails
        } finally {
            setLoading(false); // Set loading state to false once the request is done
        }
    };

    return (
        <>
            <div className="font-[sans-serif] mb-3">
                <div className="flex flex-col items-center justify-center">
                    <div className="grid md:grid-cols-2 items-center gap-4 lg:gap-72 max-w-6xl w-full">
                        <div className="lg:h-[400px] md:h-[300px]">
                            <img
                                src="https://res.cloudinary.com/djx69owjm/image/upload/v1730717023/Login-amico_xa12yt.png"
                                className="w-full h-full max-md:w-4/5 mx-auto block object-cover" alt="Dining Experience" />
                        </div>
                        <div className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
                            <form className="space-y-4" onSubmit={handleSubmit}>
                                <div className="">
                                    <h3 className="text-gray-800 text-2xl font-semibold">Welcome Back!</h3>
                                    <p className="text-gray-500 text-sm leading-relaxed">Enter Mobile Number to Start Our App</p>
                                </div>

                                <div>
                                    <div className="relative flex items-center">
                                        <FaPhoneAlt
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true" // hides the icon from screen readers
                                        />
                                        <input
                                            name="mobile"
                                            type="text"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                                            placeholder="Mobile Number"
                                            aria-label="Mobile number"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="relative flex items-center">
                                        <IoIosLock
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true" // hides the icon from screen readers
                                        />
                                        <input
                                            name="password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-3 rounded-lg outline-blue-600"
                                            placeholder="Enter your password"
                                        />
                                    </div>
                                </div>

                                <div className="text-sm flex float-end pb-8">
                                    <a href="#" className="text-orange-600 hover:underline font-semibold">
                                        Forgot your password?
                                    </a>
                                </div>

                                <div className="">
                                    <button type="submit" className="w-full shadow-xl py-3 px-4 text-sm font-bold tracking-wide rounded-lg text-white bg-orange-600 hover:bg-orange-700 focus:outline-none" disabled={loading}>
                                        {loading ? "Signing In..." : "Sign In"}
                                    </button>
                                </div>

                                {error && <p className="text-red-500 text-sm text-center mt-4">{error}</p>}

                                <p className="text-sm text-center text-gray-800">
                                    Don't have an account?
                                    <Link
                                        to="/register"
                                        className="text-orange-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                                    >
                                        Sign Up
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginForm;

import React from "react";
import { IoLocationOutline  } from "react-icons/io5";

const NavLinks = () => {
    return (
        <nav className="hidden sm:block bg-white border-t border-gray-200">
            <div className="lg:mx-52">
                <div className="container mx-auto px-4 flex flex-wrap items-center justify-between py-2">
                    <div className="flex flex-wrap justify-center space-x-4 text-gray-600">
                        <a href="#" className="hover:text-gray-800">Cars</a>
                        <a href="#" className="hover:text-gray-800">Bikes</a>
                        <a href="#" className="hover:text-gray-800">Scooters</a>
                        <a href="#" className="hover:text-gray-800">Used Cars</a>
                        <a href="#" className="hover:text-gray-800">Compare</a>
                        <a href="#" className="hover:text-gray-800">News & Reviews</a>
                        <a href="#" className="hover:text-gray-800">More</a>
                    </div>
                    <div className="text-gray-800 hidden md:flex">
                        <IoLocationOutline  size={20} className="mr-1" />
                        Bhubaneswar
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavLinks;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegHeart } from "react-icons/fa";

// const dummyProducts = [
//     {
//         id: 1,
//         name: "Audi Q3",
//         price: "₹ 11.00 - ₹ 20.00 lakh *",
//         image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60",
//         islaunch: 'Newly Launch'
//     },
//     {
//         id: 2,
//         name: "BMW X1",
//         price: "₹ 12.00 - ₹ 22.00 lakh *",
//         image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1675079349.jpg?w=600&q=60?w=1920&q=60",
//         islaunch: ''
//     },
//     {
//         id: 3,
//         name: "Mercedes GLA",
//         price: "₹ 15.00 - ₹ 25.00 lakh *",
//         image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702531230.jpg?w=600&q=60?w=1920&q=60",
//         islaunch: ''
//     },
//     {
//         id: 4,
//         name: "Volvo XC40",
//         price: "₹ 18.00 - ₹ 28.00 lakh *",
//         image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60?w=1920&q=60",
//         islaunch: 'Newly Launch'
//     },
//     {
//         id: 5,
//         name: "Jaguar E-Pace",
//         price: "₹ 20.00 - ₹ 30.00 lakh *",
//         image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661240340.jpg?w=600&q=60?w=1920&q=60",
//         islaunch: ''
//     },
// ];

const CardProducts = ({ setProID, selectedCategories, selectedtype }) => {
    console.log('selectedCategories :', selectedCategories)

    // Initialize `selectedTab` with `selectedCategories` if available, else default to `1`
    const [selectedTab, setSelectedTab] = useState(selectedCategories || 1);
    const [vehicleData, setVehicleData] = useState(null);
    const [showAll, setShowAll] = useState(false); // State to control the view
    const navigate = useNavigate();

    const tabs = [
        { id: 1, category: "Car" },
        { id: 2, category: "Bike" },
        { id: 3, category: "Tracktor" },
        { id: 4, category: "Commercial" },
    ];

    // Ensure the `selectedTab` updates dynamically when `selectedCategories` changes
    useEffect(() => {
        if (selectedCategories) {
            setSelectedTab(selectedCategories);
        }
    }, [selectedCategories]);

    useEffect(() => {
        const fetchVehicleData = async (selectedTab) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/getVehicle`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ categID: selectedTab }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setVehicleData(data.result);
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchVehicleData(selectedTab || selectedTab);
    }, [selectedtype, selectedTab]);

    const handleClickDetails = (intId) => {
        if (intId) {
            setProID(intId);
            navigate('/details');
        }
    };

    // Products to display based on the state
    const displayedProducts = showAll ? vehicleData : vehicleData?.slice(0, 5);

    return (
        <>
            <div className="bg-white shadow-md rounded-t-md mt-3">
                <div>
                    <div className="flex gap-8">
                        {tabs.map((tab) => (
                            <div key={tab.id} className="relative cursor-pointer">
                                <h4
                                    onClick={() => setSelectedTab(tab.id)}
                                    className={`pb-3 pl-5 ${selectedTab === tab.id
                                        ? "text-orange-500"
                                        : "text-gray-700"
                                        }`}
                                >
                                    {tab.category}
                                </h4>
                                {selectedTab === tab.id && (
                                    <div className="absolute bottom-0 left-0 w-full h-1 bg-orange-500"></div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-white shadow-md rounded-b-md mt-0.5">
                <div className="flex items-center">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 p-3">
                        {displayedProducts && displayedProducts.map((product) => (
                            <div
                                key={product.id}
                                className="bg-white border shadow-lg rounded-lg flex flex-col p-1 w-full text-sm cursor-pointer"
                                onClick={handleClickDetails.bind(this, product.intId)}
                            >
                                <a className="block flex-grow">
                                    <div>
                                        <div className="overflow-hidden bg-white mx-auto mb-2 rounded-lg">
                                            <div className="flex justify-end p-2">
                                                <a
                                                    className={`text-black text-xs`}
                                                >
                                                    <FaRegHeart />
                                                </a>
                                            </div>

                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/storage/app/uploads/autografi/${product.VCHCOIMAGE}`}
                                                alt={product.VCHMODELNAME}
                                                className="transform hover:scale-125 transition-transform duration-300"
                                                style={{ width: "100%", height: "50%" }}
                                            />
                                        </div>
                                        <div className="ml-2">
                                            <h3 className="font-semibold mb-1">{product.VCHMODELNAME}</h3>
                                            <p className="mb-1 text-[12px]">₹ {product.VCHPRICE}</p>
                                        </div>
                                    </div>
                                </a>
                                <div className="text-center mt-2">
                                    <button className="text-orange-500 border border-orange-500 bg-transparent py-1 font-bold w-full rounded transition mt-2 hover:bg-orange-500 hover:text-white">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-end px-4 pb-2">
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className="text-sm font-bold text-orange-500"
                    >
                        {showAll ? "View Less" : "View All"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default CardProducts;

import React from "react";
import ProductDetails from "../Product/productDetails";
import MostViewedProduct from "../Product/mostViewedProduct";
import Product from "../Product/products";
import ReviewCard from "../Home/reviewCard";
import News from '../Home/news';
import Featured from "../Home/featuredIn";


const DetailsPage = ({ proId }) => {
    return (
        <>
            <div className="mx-1 lg:mx-32 mt-3 mb-5">
                <ProductDetails proId={proId} />
                <Product />
                <ReviewCard />
                <MostViewedProduct />
                <News />
                <Featured />
            </div>
        </>
    )
};
export default DetailsPage;
import React from "react";
import { FaCar } from "react-icons/fa";

const BankPartners = () => {
    const options = ["Car", "Car", "Car", "Car"];
    const data = [
        {
            id: 1,
            image: 'https://d28wu8o6itv89t.cloudfront.net/images/CanaraBankpng-1592484768188.png',
            name: 'Canara Bank'
        },
        {
            id: 2,
            image: 'https://d28wu8o6itv89t.cloudfront.net/images/CanaraBankpng-1592484768188.png',
            name: 'Canara Bank'
        },
        {
            id: 3,
            image: 'https://d28wu8o6itv89t.cloudfront.net/images/CanaraBankpng-1592484768188.png',
            name: 'Canara Bank'
        },
        {
            id: 4,
            image: 'https://d28wu8o6itv89t.cloudfront.net/images/CanaraBankpng-1592484768188.png',
            name: 'Canara Bank'
        },
        {
            id: 5,
            image: 'https://d28wu8o6itv89t.cloudfront.net/images/CanaraBankpng-1592484768188.png',
            name: 'Canara Bank'
        },
        {
            id: 6,
            image: 'https://d28wu8o6itv89t.cloudfront.net/images/CanaraBankpng-1592484768188.png',
            name: 'Canara Bank'
        },
    ];

    return (
        <div className=" mt-5 bg-white shadow-md rounded-md flex flex-col p-4 space-y-6">
            <div className="mb-2">
                <h2 className="text-2xl font-bold">
                    Our <span className="text-orange-500 me-1">Lending Partner</span>
                    Simple and Hassle Free
                </h2>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full max-w-4xl">
                {data.map((option, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center bg-white border border-gray-300 p-4 rounded-md"
                    >
                        <div className="flex justify-center items-center">
                            <img
                                src={option.image}
                            />
                        </div>
                        <p className="mt-2 font-medium text-gray-700">{option.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BankPartners;

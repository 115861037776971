import React from "react";
import { FaCar } from "react-icons/fa";

const LoanOptions = () => {
  const options = ["Car", "Car", "Car", "Car"];

  return (
    <div className="mt-5 bg-white shadow-md rounded-md flex flex-col p-4 space-y-6">
      <h1 className="text-xl font-semibold">
        Get easy loan according to your Requirements
      </h1>
      <div className="flex justify-center w-full max-w-4xl overflow-x-auto space-x-4">
        {options.map((option, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-gray-200 p-4 rounded-md shadow-md hover:shadow-lg transition-shadow flex-shrink-0"
            style={{ width: "clamp(80px, 10vw, 150px)", height: "clamp(100px, 12vw, 180px)" }}
          >
            <div
              className="flex justify-center items-center bg-yellow-300 rounded-full"
              style={{ width: "clamp(40px, 5vw, 60px)", height: "clamp(40px, 5vw, 60px)" }}
            >
              <FaCar className="text-blue-600" size={32} />
            </div>
            <p className="mt-2 font-medium text-gray-700 text-center">{option}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoanOptions;

import React, { useState, useRef } from "react";
import { GrCar } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const services = [
  { id: 1, name: "RTO & Challan Check", icon: <GrCar className="w-12 h-12 mb-4 text-gray-600" />, path: "/RTO-challan" },
  { id: 2, name: "Used Auto Buy & Sell", icon: <GrCar className="w-12 h-12 mb-4 text-gray-600" />, path: "/insurance" },
  { id: 3, name: "Personal & Auto Loan", icon: <GrCar className="w-12 h-12 mb-4 text-gray-600" />, path: "/personal-loan" },
  { id: 4, name: "Auto Parts & Accessories", icon: <GrCar className="w-12 h-12 mb-4 text-gray-600" /> },
  { id: 5, name: "Car Wash & Detailing", icon: <GrCar className="w-12 h-12 mb-4 text-gray-600" /> },
];

const OurServices = () => {
  const scrollRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    const slider = scrollRef.current;
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseLeave = () => setIsMouseDown(false);
  const handleMouseUp = () => setIsMouseDown(false);

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const slider = scrollRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;
    slider.scrollLeft = scrollLeft - walk;
  };

  const handleServiceClick = (path) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <section>
      <div className="bg-blue-800 rounded-md p-4 mt-5 text-white shadow-lg">
        <div className="flex justify-between items-center mb-4 px-2">
          <h2 className="text-xl font-bold">Very Useful Services</h2>
        </div>

        <div
          ref={scrollRef}
          className="flex justify-center overflow-x-auto snap-x snap-mandatory cursor-grab active:cursor-grabbing scrollbar-hide"
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitUserSelect: "none",
          }}
        >
          <div className="flex gap-4 pb-4">
            {services.map((service) => (
              <div
                key={service.id}
                className="flex-none w-28 md:w-32 lg:w-40 snap-start bg-white rounded-lg shadow-md text-black p-4 flex flex-col items-center justify-center transition-transform duration-300 hover:shadow-lg cursor-pointer"
                onClick={() => handleServiceClick(service.path)}
              >
                {service.icon}
                <p className="text-center text-sm font-bold">{service.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default OurServices;

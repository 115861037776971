import React from "react";
import { FaTimes } from "react-icons/fa";
import LogoSection from "./logoSection";

const MobileMenu = ({ menuOpen, toggleMenu }) => {
    return (
        <nav
            className={`fixed top-0 left-0 w-full h-full bg-white z-50 transform ${
                menuOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-300 sm:hidden`}
        >
            <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
                <div className="flex items-center space-x-2">
                    <LogoSection />
                </div>
                <button
                    onClick={toggleMenu}
                    className="text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                    <FaTimes />
                </button>
            </div>
            <div className="flex flex-col items-center justify-center h-full space-y-6 text-gray-600">
                <a href="#" className="hover:text-gray-800 text-lg">Cars</a>
                <a href="#" className="hover:text-gray-800 text-lg">Bikes</a>
                <a href="#" className="hover:text-gray-800 text-lg">Scooters</a>
                <a href="#" className="hover:text-gray-800 text-lg">Used Cars</a>
                <a href="#" className="hover:text-gray-800 text-lg">Compare</a>
                <a href="#" className="hover:text-gray-800 text-lg">News & Reviews</a>
                <a href="#" className="hover:text-gray-800 text-lg">More</a>
            </div>
        </nav>
    );
};

export default MobileMenu;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const OtpCode = ({ logData }) => {
    const [otp, setOtp] = useState(['', '', '', '']); // State to track OTP input values
    const [focusedIndex, setFocusedIndex] = useState(0); // Track the focused input
    const [loading, setLoading] = useState(false); // Track loading state
    const [error, setError] = useState(""); // Track error message
    const inputRefs = useRef([]); // To hold references to all input fields
    const navigate = useNavigate();


    // Handle input changes
    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== '' && index < 3) {
                setFocusedIndex(index + 1); // Focus next input if current is filled
            }
        }
    };

    // Handle Backspace key
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                setFocusedIndex(index - 1); // Move focus to previous input
            }
        }
    };

    // Handle paste event for multi-character OTP
    const handlePaste = (e) => {
        e.preventDefault();
        const pasteText = e.clipboardData.getData('text');
        if (/^\d{4}$/.test(pasteText)) {
            setOtp(pasteText.split(''));
            setFocusedIndex(3); // Move to submit button after paste
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('');
        console.log("OTP Submitted:", enteredOtp);

        setLoading(true);
        setError("");

        const requestBody = {
            mobile: logData.mobile,
            otp: enteredOtp,
            password: logData.password
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/validate-otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error("Failed to validate OTP");
            }

            const data = await response.json();
            console.log(data);

            if (data.message === "authorized") {
                localStorage.setItem('isLogged', 'true');
                console.log('User is logged in and data stored in localStorage');
            }

            setOtp(['', '', '', '']);
            window.location.reload(navigate('/'));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Automatically focus the first empty input
    useEffect(() => {
        if (inputRefs.current[focusedIndex]) {
            inputRefs.current[focusedIndex].focus();
        }
    }, [focusedIndex]);

    return (
        <div className="font-[sans-serif] mb-3">
            <div className="flex flex-col items-center justify-center">
                <div className="grid md:grid-cols-2 items-center gap-4 lg:gap-72 max-w-6xl w-full">
                    <div className="lg:h-[400px] md:h-[300px]">
                        <img
                            src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1730885356/Enter_OTP-rafiki_hmmeqn.png"
                            className="w-full h-full max-md:w-4/5 mx-auto block object-cover"
                            alt="Dining Experience"
                        />
                    </div>

                    <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                        <header>
                            <h1 className="text-xl font-bold mb-1">Enter Verification Code</h1>
                            <p className="text-sm text-slate-500">
                                We are automatically detecting an SMS sent to your mobile number {logData.mobile}
                            </p>
                        </header>
                        <form id="otp-form" onSubmit={handleSubmit}>
                            <div className="flex items-center justify-center gap-3">
                                {otp.map((value, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        value={value}
                                        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded-lg p-4 outline-none focus:bg-white focus:border-orange-400 focus:ring-2 focus:ring-orange-100"
                                        maxLength="1"
                                        onInput={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onFocus={(e) => e.target.select()}
                                        onPaste={handlePaste}
                                    />
                                ))}
                            </div>
                            <div className="flex justify-end mx-14 mt-2">
                                <a className="text-xs text-orange-500 hover:text-orange-600" href="#0">
                                    Resend OTP
                                </a>
                            </div>
                            <div className="max-w-[260px] mx-auto mt-4">
                                <button
                                    type="submit"
                                    className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-orange-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-orange-950/10 hover:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-orange-300 transition-colors duration-150"
                                    disabled={loading}
                                >
                                    {loading ? "Verifying OTP..." : "Enter OTP"}
                                </button>
                            </div>
                        </form>

                        {error && <p className="text-red-500 text-sm text-center mt-4">{error}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpCode;

import React from "react";
import OurServices from "../Home/ourServices";
import { Navigate, useNavigate } from "react-router-dom";

const details = [
    "Owner's Name",
    "Registration Date",
    "Fuel Type",
    "Manufacturer and Model Name",
    "Class or Type of Vehicle",
    "Road Tax Details",
    "Insurance Expiry Date",
    "Fitness or Registration Expiry Date",
    "Registration Certificate Status",
    "Emission Norms Details",
    "Pollution Under Control Certificate (PUCC) Expiry Date",
  ];


const RTOchallan = () => {
    const navigate = useNavigate();

    const handleDetails = () => {
        navigate('/RTO-details')
      }
    
    return (
        <>
            <div className="bg-gray-100 flex justify-center items-center">
                {/* Hero Section */}
                <div
                    className="w-full h-[60vh] md:h-[70vh] p-2 flex items-end relative"
                    style={{
                        backgroundImage:
                            "url('https://images.91wheels.com/assets/b_images/main/models/profile/profile1661240340.jpg?w=600&q=60?w=1920&q=60')",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    {/* Overlay with opacity */}
                    <div className="absolute inset-0 bg-black bg-opacity-70"></div>

                    {/* Content Section */}
                    <div className="w-full overflow-hidden p-4 relative z-10">
                        {/* Input and Button Section */}
                        <div className="space-y-4 md:space-y-0 md:flex md:space-x-4">
                            {/* Input Placeholder */}
                            <div className="h-12 w-full md:flex-1 rounded bg-gray-300">
                                <input
                                    type="text"
                                    placeholder="Enter your vehicle number (e.g DL1AB1234)"
                                    className="w-full h-full px-4 rounded text-gray-700 bg-transparent focus:outline-none"
                                />
                            </div>

                            {/* Button Placeholder */}
                            <div className="h-12 w-full md:w-1/2 rounded bg-orange-500">
                                <button className="w-full h-full text-white font-semibold rounded"
                                onClick={handleDetails}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:mx-24 mb-5">
                <OurServices />
            </div>

            <div className="mb-5">
                <div className="bg-gray-100 flex justify-center items-center">
                    <div className="bg-white shadow-lg rounded-lg p-6 max-w-xl w-full">
                        <h2 className="text-lg md:text-xl font-semibold text-gray-800 mb-4">
                            What Are the Details You Get with the Vehicle Owner Search?
                        </h2>
                        <ul className="list-decimal list-inside text-gray-700 space-y-2">
                            {details.map((detail, index) => (
                                <li key={index} className="text-sm md:text-base">
                                    {detail}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
};

export default RTOchallan;

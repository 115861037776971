import React from "react";
import CardProducts from "./cardProduct";
import BrandList from "./brandList";


const Product = ({ setProID, selectedCategories, selectedtype }) => {
    return (
        <>
            <CardProducts 
            setProID={setProID} 
            selectedCategories={selectedCategories}
            selectedtype={selectedtype} 
            />

            <BrandList selectedCategories={selectedCategories} />
        </>
    )
};
export default Product;
import React, { useRef, useState } from "react";
import { FaRegHeart } from "react-icons/fa";

const ProductCard = ({ product }) => {
    return (
        <div className="bg-white border border-gray-300 rounded-md shadow-md w-48 p-3 mb-3 flex-shrink-0 select-none">
            <div className="relative group">
                <div className="flex justify-end p-2">
                    <a
                        href="#"
                        className={`text-black text-xs`}
                    >
                        <FaRegHeart />
                    </a>
                </div>
                <img
                    className="transform transition-transform duration-300 ease-in-out group-hover:scale-110"
                    style={{ width: "100%", height: "70%" }}
                    src={product.image}
                    alt={product.title}
                    draggable="false"
                />
            </div>

            <h3 className="text-base font-semibold text-gray-800 mb-2 truncate">
                {product.name}
            </h3>
            <div className="flex justify-between items-center text-gray-800">
                <span className="text-xs mb-2">{product.price}</span>
            </div>
            <button className="text-orange-500 border border-orange-500 bg-transparent py-1 font-bold w-full rounded transition mt-2 hover:bg-orange-500 hover:text-white">
                View Details
            </button>
        </div>
    );
};

const MostViewedProduct = () => {
    const products = [
        {
            id: 1,
            name: "Audi Q3",
            price: "₹ 11.00 - ₹ 20.00 lakh *",
            image:
                "https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60",
        },
        {
            id: 2,
            name: "BMW X1",
            price: "₹ 12.00 - ₹ 22.00 lakh *",
            image:
                "https://images.91wheels.com/assets/b_images/main/models/profile/profile1675079349.jpg?w=600&q=60?w=1920&q=60",
        },
        {
            id: 3,
            name: "Mercedes GLA",
            price: "₹ 15.00 - ₹ 25.00 lakh *",
            image:
                "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702531230.jpg?w=600&q=60?w=1920&q=60",
        },
        {
            id: 4,
            name: "Volvo XC40",
            price: "₹ 18.00 - ₹ 28.00 lakh *",
            image:
                "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60?w=1920&q=60",
        },
        {
            id: 5,
            name: "Jaguar E-Pace",
            price: "₹ 20.00 - ₹ 30.00 lakh *",
            image:
                "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661240340.jpg?w=600&q=60?w=1920&q=60",
        },
    ];

    const scrollContainerRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsScrolling(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
        scrollContainerRef.current.style.cursor = "grabbing";
    };

    const handleMouseUp = () => {
        setIsScrolling(false);
        scrollContainerRef.current.style.cursor = "grab";
    };

    const handleMouseLeave = () => {
        setIsScrolling(false);
        scrollContainerRef.current.style.cursor = "grab";
    };

    const handleMouseMove = (e) => {
        if (!isScrolling) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 1.5;
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
        <section id="featuredProducts" className="bg-white shadow-md rounded-md mt-5 mb-5">
            <div className="container mx-auto px-4">
                <div className="mb-2 pt-3">
                    <h2 className="text-xl font-bold text-gray-800 select-none">
                        Most Viewed
                    </h2>
                </div>

                <div
                    ref={scrollContainerRef}
                    className="flex overflow-x-auto gap-1 py-2 scroll-smooth cursor-grab no-scrollbar"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                    style={{
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                    }}
                >
                    {products.map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default MostViewedProduct;
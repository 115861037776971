import React from "react";
import { Link } from "react-router-dom";

const LogoSection = () => {
    return (
        <div className="flex items-center space-x-2">
            <Link to="/">
                <img
                    src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1734513476/logo_irnjx9.png"
                    alt="Logo"
                    className="h-10"
                />
            </Link>
        </div>
    );
};

export default LogoSection;

import React, { useState, useEffect } from "react";


const CategoryProduct = ({ setSelectedCategories, setSelectedType }) => {
  const [category, setCategory] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(false);

  useEffect(() => {
    // Define the function to call the API
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getcategories`, {
          method: 'POST', // HTTP method
          headers: {
            'Content-Type': 'application/json', // Ensure the content type is JSON
          },
          body: JSON.stringify({}), // Replace with your actual body data if required
        });

        // Check if the response is OK
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // Parse the JSON response
        setCategory(data.result)
        // console.log('Fetched Categories:', data); // Handle the response data
      } catch (error) {
        console.error('Error fetching categories:', error); // Handle errors
      }
    };

    fetchCategories(); // Call the function
  }, []);



  // Fetch vehicle types when a category is clicked
  const fetchVehicleTypes = async (categoryId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getVechileType`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ categID: categoryId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setVehicleTypes(data.result);
      setSelectedCategory(true);
      setSelectedCategories(categoryId);
      // console.log('cat-data :', data.result);
    } catch (error) {
      console.error("Error fetching vehicle types:", error);
    }
  };

  return (
    <>
      <div className="bg-white shadow-md rounded-md p-3">
        {!selectedCategory ? (
          <>
            <div className="flex items-center justify-between w-full">
              <div className="text-sm md:text-xl font-bold">
                Hey, What are you looking for?
              </div>
            </div>

            <div className="flex justify-center">
              <div className="flex overflow-x-hidden space-x-3 p-4">
                {category && category.map((item) => (
                  <>
                    <div
                      key={item.intId}
                      onClick={fetchVehicleTypes.bind(this, item.intId)}
                    >
                      <div
                        className="bg-white w-14 h-12 md:w-16 lg:w-48 lg:h-28 
                              shadow-md rounded-xl flex flex-col 
                              items-center justify-center text-center mb-1 
                              flex-shrink-0"
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}/storage/app/uploads/autografi/${item.VCHIMAGE}`}
                          className="w-full object-cover"
                          alt={item.name}
                        />
                      </div>
                      <p className="text-center text-sm">{item.VCHCATAGORY}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center justify-between w-full">
              <div className="text-sm md:text-xl font-bold">
                Showing Results
              </div>
              <div>
                <a className="text-sm hover:underline cursor-pointer"
                  onClick={() => {
                    setSelectedCategory(false);
                    setSelectedCategories(1);
                  }}
                >
                  Back to Categories
                </a>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="flex overflow-x-hidden space-x-3 p-4">
                {vehicleTypes && vehicleTypes.map((item) => (
                  <>
                    <div>
                      <div
                        className="bg-white w-14 h-12 md:w-16 lg:w-48 lg:h-28 
                              shadow-md rounded-xl flex flex-col 
                              items-center justify-center text-center mb-1 
                              flex-shrink-0"
                        onClick={() => setSelectedType(item.vechileTypeId)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}/storage/app/uploads/autografi/${item.VCHVEHICLIMG}`}
                          className="w-full object-cover"
                          alt={item.name}
                        />
                      </div>
                      <p className="text-center text-sm">{item.VCHVEHICTYPE}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CategoryProduct;

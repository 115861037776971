import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { IoIosLock } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const RegisterForm = () => {
    const [formData, setFormData] = useState({
        username: "",
        mobnumber: "",
        password: "",
        confirmPassword: "",
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        setError(null);
        setLoading(true);
        // 7766554433
        // Creating FormData instance and appending fields
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.username);
        formDataToSend.append("mobile", formData.mobnumber);
        formDataToSend.append("password", formData.password);
        formDataToSend.append("password_confirmation", formData.confirmPassword);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
                method: "POST",
                body:  formDataToSend
            });

            const result = await response.json();

            if (response.ok) {
                alert("Registration successful!");
                setFormData({
                    username: "",
                    mobnumber: "",
                    password: "",
                    confirmPassword: "",
                });
                navigate('/login');
            } else {
                setError(result.message || "Registration failed.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <div className="font-[sans-serif] mb-3">
                <div className="flex fle-col items-center justify-center">
                    <div className="grid md:grid-cols-2 items-center gap-4 lg:gap-72 max-w-6xl w-full">
                        <div className="lg:h-[400px] md:h-[300px]">
                            <img
                                src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1730808813/Mobile_login-amico_ji6hyy.png"
                                className="w-full h-full max-md:w-4/5 mx-auto block object-cover" alt="Dining Experience" />
                        </div>
                        <div
                            // className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto"
                            className="border border-gray-300 rounded-lg p-4 max-w-md max-h-[400px] shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto"

                        >
                            <form className="space-y-4" onSubmit={handleSubmit}>
                                <div className="">
                                    <h3 className="text-gray-800 text-2xl font-semibold">Create an Account!</h3>
                                    <p className="text-gray-500 text-sm leading-relaxed">Fill Below Details and start enjoy</p>
                                </div>

                                {error && (
                                    <div className="text-red-500 text-sm mb-4">{error}</div>
                                )}

                                <div>
                                    <div className="relative flex items-center">
                                        <FaUser
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                        <input
                                            name="username"
                                            type="text"
                                            value={formData.username}
                                            onChange={handleChange}
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                                            placeholder="Full Name"
                                            aria-label="User name"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="relative flex items-center">
                                        <FaPhoneAlt
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                        <input
                                            name="mobnumber"
                                            type="number"
                                            value={formData.mobnumber}
                                            onChange={handleChange}
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="relative flex items-center">
                                        <IoIosLock
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                        <input
                                            name="password"
                                            type="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                                            placeholder="Password"
                                            aria-label="Password"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="relative flex items-center">
                                        <IoIosLock
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                        <input
                                            name="confirmPassword"
                                            type="password"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                                            placeholder="Confirm Password"
                                            aria-label="Confirm Password"
                                        />
                                    </div>
                                </div>

                                <div className="">
                                    <button
                                        type="submit"
                                        className="w-full shadow-xl py-2 px-4 text-sm font-bold tracking-wide rounded-lg text-white bg-orange-600 hover:bg-orange-700 focus:outline-none"
                                        disabled={loading}
                                    >
                                        {loading ? "Loading..." : "Get Started"}
                                    </button>
                                </div>

                                <p className="text-sm text-center text-gray-800">
                                    Already have an account
                                    <Link
                                        to="/login"
                                        className="text-orange-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                                    >
                                        Sign In
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterForm;

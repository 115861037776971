import React from "react";
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white shadow-md text-gray-700 text-sm">
      <div className="max-w-screen-xl mx-auto px-6 py-10 flex flex-col lg:flex-row gap-8 lg:gap-0">
        {/* Left Section */}
        <div className="flex-1 text-center lg:text-left">
          <img
            src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1734513476/logo_irnjx9.png"
            alt="Logo"
            className="h-10"
          />
          <p className="mr-8 text-justify text-gray-600 mt-10">
            Tata’s current lineup consists of a total of 12 models which also includes four EVs. Tata’s current lineup consists of a total of 12 models which also includes four EVs.
          </p>
        </div>

        {/* Middle Section */}
        <div className="flex-1 text-center lg:text-left">
          <h3 className="font-semibold text-black mb-4">About AutoGrafi</h3>
          <ul className="space-y-2">
            <li>About</li>
            <li className="text-blue-500 hover:underline cursor-pointer">Careers With Us</li>
            <li className="text-blue-500 hover:underline cursor-pointer">Terms & Conditions</li>
            <li className="text-blue-500 hover:underline cursor-pointer">Privacy Policy</li>
            <li>Corporate Policies</li>
            <li>Investors</li>
            <li>FAQs</li>
          </ul>
        </div>

        {/* Right Section */}
        <div className="flex-1 text-center lg:text-left">
          <h3 className="font-semibold text-black mb-4">Connect With Us</h3>
          <ul className="space-y-2">
            <li>About</li>
            <li className="text-blue-500 hover:underline cursor-pointer">Careers With Us</li>
            <li className="text-blue-500 hover:underline cursor-pointer">Terms & Conditions</li>
            <li className="text-blue-500 hover:underline cursor-pointer">Privacy Policy</li>
            <li>Corporate Policies</li>
            <li>Investors</li>
            <li>FAQs</li>
          </ul>
        </div>

        {/* App Experience */}
        <div className="flex-1 text-center lg:text-left">
          <h3 className="font-semibold text-black mb-4">EXPERIENCE AUTOGRAFI APP</h3>
          <div className="flex justify-center lg:justify-start gap-4 mb-4">
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="App Store"
              className="w-28"
            />
            <img
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              alt="Google Play"
              className="w-32"
            />

          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t py-4">
        <div className="max-w-screen-xl mx-auto px-6 flex flex-col lg:flex-row justify-between items-center">
          <p>© 2024 Raytreon Global Pvt Ltd.</p>
          <div className="flex gap-4 mt-4 lg:mt-0 text-xl">
            <a href="#" className="hover:text-blue-500">
              <FaFacebookF />
            </a>
            <a href="#" className="hover:text-blue-500">
              <FaTwitter />
            </a>
            <a href="#" className="hover:text-red-500">
              <FaYoutube />
            </a>
            <a href="#" className="hover:text-pink-500">
              <FaInstagram />
            </a>
            <a href="#" className="hover:text-blue-600">
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

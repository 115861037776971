import React, { useState, useEffect } from "react";
import { FaRegHeart } from "react-icons/fa";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const dummyProducts2 = [
    {
        id: 1,
        name: "Audi Q3",
        price: "₹ 11.00 - ₹ 20.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60",
        islaunch: 'Newly Launch'
    },
    {
        id: 2,
        name: "BMW X1",
        price: "₹ 12.00 - ₹ 22.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1675079349.jpg?w=600&q=60?w=1920&q=60",
        islaunch: ''
    },
    // {
    //     id: 3,
    //     name: "Mercedes GLA",
    //     price: "₹ 15.00 - ₹ 25.00 lakh *",
    //     image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702531230.jpg?w=600&q=60?w=1920&q=60",
    //     islaunch: ''
    // },
    // {
    //     id: 4,
    //     name: "Volvo XC40",
    //     price: "₹ 18.00 - ₹ 28.00 lakh *",
    //     image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60?w=1920&q=60",
    //     islaunch: 'Newly Launch'
    // },
    // {
    //     id: 5,
    //     name: "Jaguar E-Pace",
    //     price: "₹ 20.00 - ₹ 30.00 lakh *",
    //     image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661240340.jpg?w=600&q=60?w=1920&q=60",
    //     islaunch: ''
    // },
];

// const variants = [
//     { id: 1, name: "ZXI", price: "10.98L", breakdown: "Details about ZXI variant." },
//     { id: 2, name: "ZVI", price: "11.00L", breakdown: "Details about ZVI variant." },
//     { id: 3, name: "ZZX", price: "11.98L", breakdown: "Details about ZZX variant." },
//     { id: 4, name: "CVI", price: "12.00L", breakdown: "Details about CVI variant." },
//     { id: 5, name: "CDI", price: "12.50L", breakdown: "Details about CDI variant." },
//     { id: 6, name: "CTI", price: "12.80L", breakdown: "Details about CTI variant." },
//     { id: 7, name: "XCI", price: "13.00L", breakdown: "Details about XCI variant." },
//     { id: 8, name: "CVIU", price: "13.50L", breakdown: "Details about CVIU variant." },
// ];


const ProductDetails = ({ proId }) => {
    const [proData, setProData] = useState([]);
    const [open, setOpen] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);
    const [specificData, setSpecificData] = useState('Specifications');

    const toggleAccordion = (index) => {
        setOpen(open === index ? null : index);
    };


    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/getVehicle`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ intId: proId })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setProData(data.result);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="">
                {proData && proData.map((item, index) => (
                    <>
                        <div className="bg-gray-200 shadow-md rounded-lg flex flex-col lg:flex-row overflow-hidden">
                            {/* Image Section */}
                            <div className="relative w-full lg:w-1/2 bg-white">
                                {item.MVI_VCHVEHIMG.map((image, index) => (
                                    <img
                                        key={index} // Unique key for each image
                                        src={`${process.env.REACT_APP_API_URL}/storage/app/uploads/autografi/${image}`} // Construct the full URL
                                        alt={`${item.VCHMODELNAME} - ${index + 1}`} // Add alt text
                                        className="object-cover w-full h-auto"
                                    />
                                ))}
                                {/* <img
                                    src="https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60?w=1920&q=60"
                                    alt="Mahindra Thar Roxx"
                                    className="object-cover w-full h-auto"
                                /> */}
                                {/* Image & Color Options */}
                                <div className="flex justify-center space-x-5 text-xs text-gray-700 mb-5 -mt-5">
                                    <div className="flex items-center">
                                        <div className="w-2.5 h-2.5 bg-black rounded-full"></div>
                                        <span>+{item.MVI_VCHVEHIMG.length} images</span>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-2.5 h-2.5 bg-blue-600 rounded-full"></div>
                                        <span>+{item.M_VECH_COL.length} color</span>
                                    </div>
                                </div>
                            </div>

                            {/* Content Section */}
                            <div className="w-full lg:w-1/2 p-5 bg-gray-200">
                                {/* Title */}
                                <h2 className="text-lg font-bold text-gray-900 mb-2">
                                    {item.VCHMODELNAME}
                                </h2>

                                {/* Price */}
                                <p className="text-xl font-semibold text-black mb-4">
                                    ₹{item.VCHPRICE}
                                </p>

                                <p
                                    className="text-justify text-xs overflow-hidden text-ellipsis"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3, // Limit to 3 lines
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {item.VCHOVERVIEW}
                                </p>


                                {/* Placeholder Buttons */}
                                <div className="flex space-x-3 mt-24">
                                    <div className="w-1/3 text-center font-bold p-2 bg-gray-300 rounded-lg">EMI Calculator</div>
                                    <div className="w-1/3 text-center text-white font-bold p-2 bg-orange-500 rounded-lg">Enquiry Now</div>
                                </div>
                            </div>
                        </div>

                        {/* Specification Section */}
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-2 mt-5">
                            {/* Tabs Section */}
                            <div className="col-span-12 md:col-span-9">
                                <div className="bg-gray-200 rounded-md p-2">
                                    <div className="lg:mx-10 cursor-pointer flex justify-between items-center gap-2 md:gap-5 flex-nowrap overflow-x-auto">
                                        <a className={`${specificData === 'Overview' ? 'bg-white rounded-md' : ''} p-1 text-center text-xs md:text-base whitespace-nowrap`}
                                            onClick={() => setSpecificData('Overview')}
                                        >
                                            Overview
                                        </a>
                                        <a className={`${specificData === 'Specifications' ? 'bg-white rounded-md' : ''} p-1 text-center text-xs md:text-base whitespace-nowrap`}
                                            onClick={() => setSpecificData('Specifications')}
                                        >
                                            Specifications
                                        </a>
                                        <a className={`${specificData === 'Variant' ? 'bg-white rounded-md' : ''} p-1 text-center text-xs md:text-base whitespace-nowrap`}
                                            onClick={() => setSpecificData('Variant')}
                                        >
                                            Variant
                                        </a>
                                        <a className={`${specificData === 'Price By City' ? 'bg-white rounded-md' : ''} p-1 text-center text-xs md:text-base whitespace-nowrap`}
                                            onClick={() => setSpecificData('Price By City')}
                                        >
                                            Price By City
                                        </a>
                                        <a className={`${specificData === 'Review' ? 'bg-white rounded-md' : ''} p-1 text-center text-xs md:text-base whitespace-nowrap`}
                                            onClick={() => setSpecificData('Review')}
                                        >
                                            Review
                                        </a>
                                    </div>
                                </div>

                                {specificData && specificData === 'Overview' ? (
                                    <div
                                        className="bg-white"
                                    >
                                        <p className=" mx-5 text-justify text-xs overflow-hidden text-ellipsis">
                                            {item && item.VCHOVERVIEW}
                                        </p>
                                    </div>
                                )
                                    :
                                    specificData === 'Specifications' ? (
                                        <div>
                                            <div className="space-y-0.5">
                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(1)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Engine & Transmission</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 1 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 1 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_ENG_TRANS.length > 0 ? (
                                                                item.M_ENG_TRANS.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(2)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Dimension & Capacity</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 2 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 2 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_DEM_CAPA.length > 0 ? (
                                                                item.M_DEM_CAPA.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(3)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Suspension, Steering & Breaks</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 3 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 3 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_SUSP_BREAK.length > 0 ? (
                                                                item.M_SUSP_BREAK.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(4)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Safety</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 4 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 4 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_SAFETY.length > 0 ? (
                                                                item.M_SAFETY.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(5)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Comfort & Convenience</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 5 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 5 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_COMF_CONV.length > 0 ? (
                                                                item.M_COMF_CONV.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(6)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Interior</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 6 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 6 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_INTERIOR.length > 0 ? (
                                                                item.M_INTERIOR.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(7)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Exterior</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 7 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 7 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_EXTERIOR.length > 0 ? (
                                                                item.M_EXTERIOR.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="bg-white">
                                                    <div
                                                        onClick={() => toggleAccordion(8)}
                                                        className="w-full flex justify-between items-center py-2 px-5 text-slate-800"
                                                    >
                                                        <span>Info & Communication</span>
                                                        <span
                                                            className={`text-slate-800 transition-transform duration-300 ${open === 8 ? '' : 'rotate-180'
                                                                }`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                className="w-4 h-4"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 8 ? 'max-h-screen' : 'max-h-0'
                                                            }`}
                                                    >
                                                        <div className="pb-5 px-5 text-sm text-slate-500">
                                                            {item && item.M_INFO_COMMUN.length > 0 ? (
                                                                item.M_INFO_COMMUN.map((entry, index) => (
                                                                    <div key={index} className="mb-2 flex justify-between">
                                                                        <p>{entry.VCHKEY}</p>
                                                                        <p>{entry.VCHVALUE}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                        : specificData === 'Variant' ? (
                                            <div className="bg-white">
                                                <div className="pb-5 px-5 text-sm text-slate-500">
                                                    {item && item.M_ALL_VARIANTS.length > 0 ? (
                                                        item.M_ALL_VARIANTS.map((entry, index) => (
                                                            <div key={index} className="mb-2 flex justify-between">
                                                                <p>{entry.VCHKEY}</p>
                                                                <p>{entry.VCHVALUE}</p>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No data available</p>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                            : specificData === 'Price By City' ? (
                                                <p>No data available</p>
                                            )
                                                : (
                                                    <p>No data available</p>
                                                )
                                }

                                {/* card  */}
                                <div className="mt-3 bg-white shadow-md rounded-md">
                                    <div className="flex flex-wrap justify-between md:space-x-2 p-4">
                                        <div className="bg-green-100 shadow-lg w-full sm:w-[48%] h-w p-5 rounded-lg mb-2 sm:mb-0">
                                            <div className="inline-flex items-center mb-3 text-xl">
                                                <h4 className="flex items-center font-semibold">
                                                    <span className="mr-1 text-2xl text-green-500">
                                                        <AiOutlineLike />
                                                    </span>
                                                    Pros
                                                </h4>
                                            </div>
                                            <div className="text-sm">
                                                <nav>
                                                    <ul className="list-disc pl-4">
                                                        <li className="mb-2">
                                                        Heavily refreshed feature list.
                                                        </li>
                                                        <li className="mb-2">
                                                        Spacious cabin and comfortable seats.
                                                        </li>
                                                        <li className="mb-2">
                                                        The updated design looks sharp and appealing.
                                                        </li>
                                                        <li className="mb-2">
                                                        Powertrains have adequate grunt for most driving situations.
                                                        </li>
                                                        <li>
                                                        Hyundai spares/service availability and resale value.
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="bg-pink-100 shadow-lg w-full sm:w-[48%] h-w p-5 rounded-md mb-2 sm:mb-0">
                                            <div className="inline-flex items-center mb-3 text-xl">
                                                <h4 className="flex items-center font-semibold">
                                                    <span className="mr-1 text-2xl text-red-500">
                                                        <AiOutlineDislike />
                                                    </span>
                                                    Cons
                                                </h4>
                                            </div>
                                            <div className="text-sm">
                                                <nav>
                                                <ul className="list-disc pl-4">
                                                        <li className="mb-2">
                                                        Heavily refreshed feature list.
                                                        </li>
                                                        <li className="mb-2">
                                                        Spacious cabin and comfortable seats.
                                                        </li>
                                                        <li className="mb-2">
                                                        The updated design looks sharp and appealing.
                                                        </li>
                                                        <li className="mb-2">
                                                        Powertrains have adequate grunt for most driving situations.
                                                        </li>
                                                        <li>
                                                        Hyundai spares/service availability and resale value.
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* accord 2 for all details */}
                                {/* <div className="mt-3 bg-white shadow-md rounded-xl">
                                    <div className="flex justify-between">
                                        <h2 className="text-sm font-semibold p-2">
                                            Honda Nios all
                                            <br />
                                            <span className="text-xl">Variants</span>
                                        </h2>
                                        <h4 className="text-sm font-semibold mr-32 mt-8">Price</h4>
                                    </div>
                                    <div className="border rounded-lg shadow-md">
                                        {variants.map((variant, index) => (
                                            <div key={variant.id} className="border-b">
                                                <div
                                                    className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleToggle(index)}
                                                >
                                                    <div className="font-medium">{variant.name}</div>
                                                    <div className="flex items-center">
                                                        <span className="mr-4 text-gray-600">{variant.price}</span>
                                                        {openIndex === index ? (
                                                            <FiChevronUp className="text-gray-500" />
                                                        ) : (
                                                            <FiChevronDown className="text-gray-500" />
                                                        )}
                                                    </div>
                                                </div>
                                                {openIndex === index && (
                                                    <div className="bg-gray-50 p-4 text-sm text-gray-700">
                                                        {variant.breakdown}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="border-b bg-white p-4 rounded-lg shadow-md text-center
                        hover:bg-gray-100">
                                        <button className=" text-cyan-500">
                                            Show More
                                        </button>
                                    </div>
                                </div> */}

                            </div>

                            {/* Product Section */}
                            <div className="col-span-12 md:col-span-3 hidden md:block">
                                <div className="bg-white shadow-md p-4 rounded-md">
                                    {dummyProducts2.map((product) => (
                                        <div
                                            key={product.id}
                                            className="bg-white border shadow-lg rounded-lg flex flex-col p-1 w-full text-sm cursor-pointer"
                                        >
                                            <a className="block flex-grow">
                                                <div>
                                                    <div className="overflow-hidden bg-white mx-auto mb-2 rounded-lg">
                                                        <div className="flex justify-between p-2">
                                                            <a
                                                                href="#"
                                                                className={`${product.islaunch ? 'bg-orange-500' : ''}  text-white text-xs px-2 rounded-md`}
                                                            >
                                                                {product.islaunch ? product.islaunch : ''}
                                                            </a>

                                                            <a
                                                                href="#"
                                                                className={`text-black text-xs`}
                                                            >
                                                                <FaRegHeart />
                                                            </a>
                                                        </div>

                                                        <img
                                                            src={product.image}
                                                            alt={product.name}
                                                            className="transform hover:scale-125 transition-transform duration-300"
                                                            style={{ width: "100%", height: "50%" }}
                                                        />
                                                    </div>
                                                    <div className="ml-2">
                                                        <h3 className="font-semibold mb-1">{product.name}</h3>
                                                        <p className="mb-1 text-[12px]">{product.price}</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="text-center mt-2">
                                                <button className="text-orange-500 border border-orange-500 bg-transparent py-1 font-bold w-full rounded transition mt-2 hover:bg-orange-500 hover:text-white">
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
};

export default ProductDetails;

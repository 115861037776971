import React, { useState } from "react";
import OurServices from "../Home/ourServices";

const RTOdetails = () => {
    const [open, setOpen] = useState(null);

    const toggleAccordion = (index) => {
        setOpen(open === index ? null : index);
    };

    return (
        <>
            <div className="bg-gray-100 flex justify-center items-center px-4 my-5">
                <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-lg">
                    {/* Vehicle Details */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-800">DL1BA6895</h3>
                        <p className="text-sm text-gray-600">Rasmiranjan Parida</p>
                        <p className="text-sm text-gray-600">Hyundai Creta</p>
                    </div>

                    {/* Vehicle Image */}
                    <div className="flex justify-end mb-6">
                        <img
                            src="https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60" // Replace with your image URL
                            alt="Hyundai Creta"
                            className="w-52 h-auto object-contain"
                        />
                    </div>

                    {/* Action Buttons */}
                    <div className="flex space-x-4">
                        <button className="flex-1 h-12 bg-white border border-orange-500 text-orange-500 rounded-lg text-sm font-semibold hover:bg-orange-100 transition">
                            Button 1
                        </button>
                        <button className="flex-1 h-12 bg-white border border-orange-500 text-orange-500 rounded-lg text-sm font-semibold hover:bg-orange-100 transition">
                            Button 2
                        </button>
                    </div>
                </div>
            </div>

            <div className="md:mx-24 mb-5">
                <OurServices />
            </div>

            <div className="mb-5">
                <div className="bg-gray-100 flex justify-center items-center px-4">
                    <div className="bg-white shadow-lg rounded-lg w-full max-w-lg p-4">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">
                            Complete Ownership Details
                        </h2>

                        {/* Accordion Item 1 */}
                        <div className="bg-gray-50 rounded-lg mb-2">
                            <div
                                onClick={() => toggleAccordion(1)}
                                className="w-full flex justify-between items-center py-3 px-4 cursor-pointer"
                            >
                                <span className="font-medium text-gray-700">Owner Details</span>
                                <span
                                    className={`transition-transform duration-300 ${open === 1 ? "rotate-180" : ""
                                        }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 1 ? "max-h-screen" : "max-h-0"
                                    }`}
                            >
                                <div className="py-3 px-4 text-sm text-gray-600">
                                    This section contains the owner details, including the name,
                                    contact information, and address.
                                </div>
                            </div>
                        </div>

                        {/* Accordion Item 2 */}
                        <div className="bg-gray-50 rounded-lg mb-2">
                            <div
                                onClick={() => toggleAccordion(2)}
                                className="w-full flex justify-between items-center py-3 px-4 cursor-pointer"
                            >
                                <span className="font-medium text-gray-700">Vehicle Details</span>
                                <span
                                    className={`transition-transform duration-300 ${open === 2 ? "rotate-180" : ""
                                        }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 2 ? "max-h-screen" : "max-h-0"
                                    }`}
                            >
                                <div className="py-3 px-4 text-sm text-gray-600">
                                    This section contains vehicle details, such as make, model,
                                    registration number, and year of manufacture.
                                </div>
                            </div>
                        </div>

                        {/* Accordion Item 3 */}
                        <div className="bg-gray-50 rounded-lg">
                            <div
                                onClick={() => toggleAccordion(3)}
                                className="w-full flex justify-between items-center py-3 px-4 cursor-pointer"
                            >
                                <span className="font-medium text-gray-700">Insurance Details</span>
                                <span
                                    className={`transition-transform duration-300 ${open === 3 ? "rotate-180" : ""
                                        }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 3 ? "max-h-screen" : "max-h-0"
                                    }`}
                            >
                                <div className="py-3 px-4 text-sm text-gray-600">
                                    This section contains insurance details, including policy number,
                                    provider, and expiry date.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RTOdetails;

import React, { useState } from "react";
import Carousel from "./carousel"
import Product from "../Product/products";
import CategoryProduct from "../Product/categoryProduct";
import OurServices from "./ourServices";
import ReviewCard from "./reviewCard";
import MostViewedProduct from "../Product/mostViewedProduct";
import News from "./news";
import Featured from "./featuredIn";


const Home = ({ setProID }) => {
    const [selectedCategories, setSelectedCategories] = useState(1);
    const [selectedtype, setSelectedType] = useState('');
    console.log('selectedtype :', selectedtype);
    return (
        <>
            <Carousel />
            <div className="mx-1 md:mx-32 mt-3 mb-5">
                <CategoryProduct 
                setSelectedCategories={setSelectedCategories}
                setSelectedType={setSelectedType}
                />
                <Product 
                setProID={setProID} 
                selectedCategories={selectedCategories} 
                selectedtype={selectedtype}
                />
                <OurServices />
                <ReviewCard />
                <MostViewedProduct />
                <News />
                <Featured />
            </div>
        </>
    )
};
export default Home;
import React from "react";
import { FaSearch } from "react-icons/fa";

const SearchBox = () => {
    return (
        <div className="flex w-full max-w-lg">
            <input
                type="text"
                placeholder="Search for Cars, Bikes & more"
                className="w-full border border-gray-300 rounded-l-full px-4 py-2 text-sm focus:outline-none"
            />
            <button className="bg-gray-800 text-white px-4 py-2 rounded-r-full hover:bg-gray-900">
                <FaSearch />
            </button>
        </div>
    );
};

export default SearchBox;

import React, { useState } from "react";
import LogoSection from "./logoSection";
import SearchBox from "./searchBox";
import IconsSection from "./iconsSection";
import MobileMenu from "./mobileMenu";
import NavLinks from "./navLinks";
import { FaBars } from "react-icons/fa";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <header
                // className="w-full border-b border-gray-200 bg-white"
                className="sticky top-0 left-0 w-full bg-white shadow-lg z-50"
            >
                <div className="lg:mx-52">
                    <div className="container mx-auto px-4 py-3 flex flex-wrap items-center justify-between gap-4 lg:gap-10">
                        {/* Mobile Menu Button */}
                        <div className="sm:hidden">
                            <button
                                onClick={toggleMenu}
                                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                            >
                                <FaBars />
                            </button>
                        </div>

                         {/* Logo Section */}
                         <div className="hidden md:block">
                            <LogoSection />
                        </div>

                        {/* Search Bar */}
                        <div className="flex-grow lg:flex items-center justify-center hidden sm:block">
                            <SearchBox />
                        </div>
                        {/* Logo Section */}
                        <div className="block md:hidden">
                            <LogoSection />
                        </div>

                        {/* Icons Section */}
                        <IconsSection />

                        {/* Search Bar */}
                        <div className="flex-grow items-center justify-center block md:hidden lg:hidden">
                            <SearchBox />
                        </div>
                    </div>
                </div>

                {/* Mobile Menu */}
                <MobileMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />

                {/* Desktop Navigation Links */}
                <NavLinks />
            </header>
        </>
    );
};

export default Header;

import React, { useRef, useState } from "react";

const dummyProducts = [
  {
    id: 1,
    description:
      "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
    image:
      "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg",
  },
  {
    id: 2,
    description:
      "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
    image:
      "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg",
  },
  {
    id: 3,
    description:
      "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
    image:
      "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg",
  },
  {
    id: 4,
    description:
      "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
    image:
      "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg",
  },
  {
    id: 5,
    description:
      "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
    image:
      "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg",
  },
];

const News = () => {
  const scrollContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsScrolling(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseUp = () => {
    setIsScrolling(false);
    scrollContainerRef.current.style.cursor = "grab";
  };

  const handleMouseLeave = () => {
    setIsScrolling(false);
    scrollContainerRef.current.style.cursor = "grab";
  };

  const handleMouseMove = (e) => {
    if (!isScrolling) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="max-w-screen-xl bg-white shadow-md rounded-md mx-auto px-4 py-3 mt-3 text-xs">
      <h2 className="text-md font-bold mb-3">Latest News</h2>
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto gap-4 py-2 scroll-smooth cursor-grab no-scrollbar transition-all duration-300 ease-in-out"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {dummyProducts.map((product) => (
          <div
            key={product.id}
            className="bg-white border border-gray-200 rounded-lg flex flex-col p-1 w-[48%] sm:w-[50%] lg:w-[20%] text-sm cursor-pointer"
          >
            <a className="block flex-grow">
              <div>
                <div className="overflow-hidden bg-white mx-auto mb-2 rounded-lg">
                  <img
                    src={product.image}
                    alt={product.description}
                    className="transform hover:scale-125 transition-transform duration-300"
                    style={{ width: "100%", height: "50%" }}
                  />
                </div>
                <div className="ml-2">
                  <p className=" text-justify mb-1 text-xs md:text-sm">
                    {product.description}
                  </p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      {/* View All */}
      <div className="mt-6">
        <button className="text-orange-500 font-bold hover:underline">
          View All »
        </button>
      </div>
    </div>
  );
};

export default News;
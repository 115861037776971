import React from "react";
import { FaRegHeart, FaUser } from "react-icons/fa";
import { MdOutlineNotificationsActive } from "react-icons/md";
import DropDownUser from "../profile/dropDownUser";

const IconsSection = () => {
    return (
        <>
            <div className="flex items-center space-x-4">
                <button className="hidden md:block text-gray-600 hover:text-gray-800">
                    <MdOutlineNotificationsActive size={20} />
                </button>
                <button className="hidden md:block text-gray-600 hover:text-gray-800">
                    <FaRegHeart size={20} />
                </button>
            </div>
            <DropDownUser />
        </>

    );
};

export default IconsSection;

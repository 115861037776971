import React from "react";
import OurServices from "../Home/ourServices";
import BankPartners from "./ourBankpatner";

const PersonalLoan = () => {
    return (
        <>
            <div>
                <img
                    src="https://pbs.twimg.com/media/EhcmmTkUMAEcAMz.jpg:large"
                    alt="p-loan-image"
                    className="w-full h-80" />
            </div>

            <div className="md:mx-24 mb-5">
                <OurServices />
            </div>

            <div className="mb-5">
                <div className="max-w-xl mx-auto bg-white rounded-lg shadow-md p-6 text-center">
                    {/* Header Section */}
                    <div className="mb-6">
                        <h1 className="text-orange-500 text-2xl font-bold">Get a Loan in 3 Simple Steps</h1>
                        <p className="text-gray-600 mt-1">Simple and Hassle Free</p>
                    </div>

                    {/* Steps Section */}
                    <div className="space-y-6 md:space-y-0 md:flex md:justify-between md:items-start">
                        {/* Step 1 */}
                        <div className="flex md:flex-col md:items-center">
                            <div className="flex-shrink-0 w-10 h-10 bg-orange-500 text-white rounded-full flex items-center justify-center text-lg font-bold">
                                1
                            </div>
                            <div className="ml-4 md:ml-0 md:mt-2 text-left md:text-center">
                                <h2 className="text-lg font-semibold text-gray-800">Share Details</h2>
                                <p className="text-gray-600 text-sm">Fill a simple form about your details</p>
                            </div>
                        </div>

                        {/* Step 2 */}
                        <div className="flex md:flex-col md:items-center">
                            <div className="flex-shrink-0 w-10 h-10 bg-orange-500 text-white rounded-full flex items-center justify-center text-lg font-bold">
                                2
                            </div>
                            <div className="ml-4 md:ml-0 md:mt-2 text-left md:text-center">
                                <h2 className="text-lg font-semibold text-gray-800">Check Quotes</h2>
                                <p className="text-gray-600 text-sm">Check quotes and eligibility</p>
                            </div>
                        </div>

                        {/* Step 3 */}
                        <div className="flex md:flex-col md:items-center">
                            <div className="flex-shrink-0 w-10 h-10 bg-orange-500 text-white rounded-full flex items-center justify-center text-lg font-bold">
                                3
                            </div>
                            <div className="ml-4 md:ml-0 md:mt-2 text-left md:text-center">
                                <h2 className="text-lg font-semibold text-gray-800">Leave the Rest to Us</h2>
                                <p className="text-gray-600 text-sm">Our loan consultant assists you</p>
                            </div>
                        </div>
                    </div>

                    {/* CTA Button */}
                    <div className="mt-8">
                        <button className="bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300">
                            Check Credit Score
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="mb-5 md:mx-24">
                <BankPartners />
            </div>
        </>
    )
};
export default PersonalLoan;